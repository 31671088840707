<template>
  <div class="main-content flex-1 relative">
    <loading-overla :visible="loading" />
    <div class="flex justify-center w-full">
      <div
        class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-10 md:px-20 justify-center items-center"
      >
        <div class="flex flex-col w-full justify-center items-center gap-4">
          <div
            class="flex justify-center border-b border-black col-span-2 w-full mt-12"
          >
            {{ $t("reports.finance") }}
          </div>
          <select
            v-model="finance.type"
            class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none w-full md:w-1/5"
          >
            <option value="year">{{ $t("reports.yearly") }}</option>
            <option value="month">{{ $t("reports.monthly") }}</option>
            <option value="day">{{ $t("reports.daily") }}</option>
            <option value="duration">{{ $t("reports.duration") }}</option>
            >
          </select>
          <div
            v-if="finance.type != 'duration'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.year") }}</label>
            <select
              v-model="finance.year"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none w-full md:w-1/5"
            >
              <option
                v-for="item in range(1990, 2999)"
                :key="item"
                :value="item"
                >{{ item }}</option
              >
              >
            </select>
          </div>
          <div
            v-if="finance.type == 'month' || finance.type == 'day'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.month") }}</label>
            <select
              v-model="finance.month"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none  w-full md:w-1/5"
            >
              <option value="01">1</option>
              <option value="02">2</option>
              <option value="03">3</option>
              <option value="04">4</option>
              <option value="05">5</option>
              <option value="06">6</option>
              <option value="07">7</option>
              <option value="08">8</option>
              <option value="09">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              >
            </select>
          </div>

          <div
            v-if="finance.type == 'day'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.day") }}</label>
            <select
              v-model="finance.day"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none  w-full md:w-1/5"
            >
              <option value="01">1</option>
              <option value="02">2</option>
              <option value="03">3</option>
              <option value="04">4</option>
              <option value="05">5</option>
              <option value="06">6</option>
              <option value="07">7</option>
              <option value="08">8</option>
              <option value="09">9</option>
              <option v-for="item in range(10, 31)" :key="item" :value="item">{{
                item
              }}</option>
              >
            </select>
          </div>
          <div
            v-if="finance.type == 'duration'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.from") }}</label>
            <input
              v-model="finance.report_date_start"
              type="date"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none text-center w-full md:w-1/5"
              placeholder="2023-01-01"
            />
            <label>{{ $t("reports.to") }}</label>
            <input
              v-model="finance.report_date_end"
              type="date"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none text-center w-full md:w-1/5"
              placeholder="2023-01-01"
            />
          </div>
          <button
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white py-1 px-12 font-bold w-full md:w-1/5"
            @click="fetchFinance"
          >
            {{ $t("reports.generate") }}
          </button>
          <div
            id="finance_report"
            v-if="report.finance"
            class="w-full bg-white p-12"
          >
            <div class="flex justify-between items-center text-xl">
              <div>
                <div>{{ settings.network_name }}</div>
              </div>
              <div class="flex flex-col items-center">
                <img src="@/assets/logo2.png" alt="" />
                <div>SSRadius</div>
                <div>{{ $t("reports.finance_report") }}</div>
                <div>{{ user.data.email }}</div>
              </div>
              <div>
                <div>{{ $t(`reports.${finance.type}`) }}</div>
                <div v-if="finance.type == 'year'">{{ finance.year }}</div>
                <div v-else-if="finance.type == 'month'">
                  {{ finance.year + "-" + finance.month }}
                </div>
                <div v-else-if="finance.type == 'day'">
                  {{ finance.year + "-" + finance.month + "-" + finance.day }}
                </div>
                <div v-else>
                  {{
                    finance.report_date_start + " -> " + finance.report_date_end
                  }}
                </div>
              </div>
            </div>
            <table class="table-auto w-full border-black">
              <thead class="border-black border-b-2">
                <th>
                  <div v-if="finance.type == 'year'">
                    {{ $t("reports.month") }}
                  </div>
                  <div v-else-if="finance.type == 'month'">
                    {{ $t("reports.day") }}
                  </div>
                  <div v-else-if="finance.type == 'day'">
                    {{ $t("reports.hour") }}
                  </div>
                  <div v-else>{{ $t("reports.day") }}</div>
                </th>
                <th>{{ $t("reports.amount") }}</th>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in report.finance"
                  :key="index"
                  class="text-center border-b"
                >
                  <td>{{ item.time }}</td>
                  <td>{{ item.amount }}</td>
                </tr>
                <tr class="bg-black text-white text-center">
                  <td>{{ $t("reports.total") }}</td>
                  <td>{{ report.finance.amount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            v-if="report.finance"
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white py-1 px-12 font-bold"
            v-print="'finance_report'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-12 h-12"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
          </button>
          <div
            class="flex justify-center border-b border-black col-span-2 w-full mt-12"
          >
            {{ $t("reports.sells") }}
          </div>
          <select
            v-model="sells.type"
            class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none w-full md:w-1/5"
          >
            <option value="year">{{ $t("reports.yearly") }}</option>
            <option value="month">{{ $t("reports.monthly") }}</option>
            <option value="day">{{ $t("reports.daily") }}</option>
            <option value="duration">{{ $t("reports.duration") }}</option>
            >
          </select>
          <div
            v-if="sells.type != 'duration'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.year") }}</label>
            <select
              v-model="sells.year"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none w-full md:w-1/5"
            >
              <option
                v-for="item in range(1990, 2999)"
                :key="item"
                :value="item"
                >{{ item }}</option
              >
              >
            </select>
          </div>
          <div
            v-if="sells.type == 'month' || sells.type == 'day'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.month") }}</label>
            <select
              v-model="sells.month"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none w-full md:w-1/5"
            >
              <option value="01">1</option>
              <option value="02">2</option>
              <option value="03">3</option>
              <option value="04">4</option>
              <option value="05">5</option>
              <option value="06">6</option>
              <option value="07">7</option>
              <option value="08">8</option>
              <option value="09">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              >
            </select>
          </div>

          <div
            v-if="sells.type == 'day'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.day") }}</label>
            <select
              v-model="sells.day"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none  w-full md:w-1/5"
            >
              <option value="01">1</option>
              <option value="02">2</option>
              <option value="03">3</option>
              <option value="04">4</option>
              <option value="05">5</option>
              <option value="06">6</option>
              <option value="07">7</option>
              <option value="08">8</option>
              <option value="09">9</option>
              <option v-for="item in range(10, 31)" :key="item" :value="item">{{
                item
              }}</option>
              >
            </select>
          </div>
          <div
            v-if="sells.type == 'duration'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.from") }}</label>
            <input
              v-model="sells.report_date_start"
              type="date"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none text-center w-full md:w-1/5"
              placeholder="2023-01-01"
            />
            <label>{{ $t("reports.to") }}</label>
            <input
              v-model="sells.report_date_end"
              type="date"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none text-center w-full md:w-1/5"
              placeholder="2023-01-01"
            />
          </div>
          <button
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white py-1 px-12 font-bold w-full md:w-1/5"
            @click="fetchSells"
          >
            {{ $t("reports.generate") }}
          </button>
          <div
            id="sells_report"
            v-if="report.sells"
            class="w-full bg-white p-12"
          >
            <div class="flex justify-between items-center text-xl">
              <div>{{ settings.network_name }}</div>
              <div class="flex flex-col items-center">
                <img src="@/assets/logo2.png" alt="" />
                <div>SSRadius</div>
                <div>{{ $t("reports.sells_report") }}</div>
                <div>{{ user.data.email }}</div>
              </div>
              <div>
                <div>{{ $t(`reports.${sells.type}`) }}</div>
                <div v-if="sells.type == 'year'">{{ sells.year }}</div>
                <div v-else-if="sells.type == 'month'">
                  {{ sells.year + "-" + sells.month }}
                </div>
                <div v-else-if="sells.type == 'day'">
                  {{ sells.year + "-" + sells.month + "-" + sells.day }}
                </div>
                <div v-else>
                  {{ sells.report_date_start + " -> " + sells.report_date_end }}
                </div>
              </div>
            </div>
            <div class="text-2xl font-bold text-center w-full mt-12">
              {{ $t("reports.accounts") }}
            </div>
            <table class="table-auto w-full border-black">
              <thead class="border-black border-b-2">
                <th>
                  <div v-if="sells.type == 'year'">
                    {{ $t("reports.month") }}
                  </div>
                  <div v-else-if="sells.type == 'month'">
                    {{ $t("reports.day") }}
                  </div>
                  <div v-else-if="sells.type == 'day'">
                    {{ $t("reports.hour") }}
                  </div>
                  <div v-else>{{ $t("reports.day") }}</div>
                </th>
                <th>{{ $t("reports.profile") }}</th>
                <th>{{ $t("reports.accounts_count") }}</th>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in report.sells.accounts"
                  :key="index"
                  class="text-center border-b"
                >
                  <td>{{ item.time }}</td>
                  <td>{{ item.profile }}</td>
                  <td>{{ item.count }}</td>
                </tr>
                <tr class="bg-black text-white text-center">
                  <td>{{ $t("reports.total") }}</td>
                  <td></td>
                  <td>{{ report.sells.accounts.count }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-2xl font-bold text-center w-full mt-12">
              {{ $t("reports.cards") }}
            </div>
            <table class="table-auto w-full border-black">
              <thead class="border-black border-b-2">
                <th>
                  <div v-if="sells.type == 'year'">
                    {{ $t("reports.month") }}
                  </div>
                  <div v-else-if="sells.type == 'month'">
                    {{ $t("reports.day") }}
                  </div>
                  <div v-else-if="sells.type == 'day'">
                    {{ $t("reports.hour") }}
                  </div>
                  <div v-else>{{ $t("reports.day") }}</div>
                </th>
                <th>{{ $t("reports.batch") }}</th>
                <th>{{ $t("reports.cards_count") }}</th>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in report.sells.cards"
                  :key="index"
                  class="text-center border-b"
                >
                  <td>{{ item.time }}</td>
                  <td>{{ item.batch_name }}</td>
                  <td>{{ item.count }}</td>
                </tr>
                <tr class="bg-black text-white text-center">
                  <td>{{ $t("reports.total") }}</td>
                  <td></td>
                  <td>{{ report.sells.cards.count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            v-if="report.sells"
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white py-1 px-12 font-bold"
            v-print="'sells_report'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-12 h-12"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
          </button>
          <div
            class="flex justify-center border-b border-black col-span-2 w-full mt-12"
          >
            {{ $t("reports.reseller") }}
          </div>
          <select
            v-model="reseller.type"
            class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none w-full md:w-1/5"
          >
            <option value="year">{{ $t("reports.yearly") }}</option>
            <option value="month">{{ $t("reports.monthly") }}</option>
            <option value="day">{{ $t("reports.daily") }}</option>
            <option value="duration">{{ $t("reports.duration") }}</option>
            >
          </select>
          <div class="flex flex-col items-center w-full">
            <label>{{ $t("admins.reseller") }}</label>

            <select
              v-model="reseller.user_id"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none w-full md:w-1/5"
            >
              <option value="-1">{{ $t("control.all") }}</option>
              <option
                v-for="(reseller, index) in resellers"
                :key="reseller.id"
                :value="reseller.id"
                >{{ reseller.email }}</option
              >
              >
            </select>
          </div>

          <div
            v-if="reseller.type != 'duration'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.year") }}</label>
            <select
              v-model="reseller.year"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none w-full md:w-1/5"
            >
              <option
                v-for="item in range(1990, 2999)"
                :key="item"
                :value="item"
                >{{ item }}</option
              >
              >
            </select>
          </div>
          <div
            v-if="reseller.type == 'month' || reseller.type == 'day'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.month") }}</label>
            <select
              v-model="reseller.month"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none  w-full md:w-1/5"
            >
              <option value="01">1</option>
              <option value="02">2</option>
              <option value="03">3</option>
              <option value="04">4</option>
              <option value="05">5</option>
              <option value="06">6</option>
              <option value="07">7</option>
              <option value="08">8</option>
              <option value="09">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              >
            </select>
          </div>

          <div
            v-if="reseller.type == 'day'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.day") }}</label>
            <select
              v-model="reseller.day"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none  w-full md:w-1/5"
            >
              <option value="01">1</option>
              <option value="02">2</option>
              <option value="03">3</option>
              <option value="04">4</option>
              <option value="05">5</option>
              <option value="06">6</option>
              <option value="07">7</option>
              <option value="08">8</option>
              <option value="09">9</option>
              <option v-for="item in range(10, 31)" :key="item" :value="item">{{
                item
              }}</option>
              >
            </select>
          </div>
          <div
            v-if="reseller.type == 'duration'"
            class="flex flex-col items-center w-full"
          >
            <label>{{ $t("reports.from") }}</label>
            <input
              v-model="reseller.report_date_start"
              type="date"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none text-center w-full md:w-1/5"
              placeholder="2023-01-01"
            />
            <label>{{ $t("reports.to") }}</label>
            <input
              v-model="reseller.report_date_end"
              type="date"
              class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none text-center w-full md:w-1/5"
              placeholder="2023-01-01"
            />
          </div>
          <button
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white py-1 px-12 font-bold w-full md:w-1/5"
            @click="fetchReseller"
          >
            {{ $t("reports.generate") }}
          </button>
          <div
            id="reseller_report"
            v-if="report.reseller"
            class="w-full bg-white p-12"
          >
            <div
              class="w-full"
              v-for="(rese, index) in report.reseller"
              :key="index"
            >
              <div class="flex justify-between items-center text-xl">
                <div>
                  <div>{{ settings.network_name }}</div>
                </div>
                <div class="flex flex-col items-center">
                  <img src="@/assets/logo2.png" alt="" />
                  <div>{{ $t("reports.reseller") }}</div>
                  <div>{{ rese.user.email }}</div>
                </div>
                <div>
                  <div>{{ $t(`reports.${reseller.type}`) }}</div>
                  <div v-if="reseller.type == 'year'">{{ reseller.year }}</div>
                  <div v-else-if="reseller.type == 'month'">
                    {{ reseller.year + "-" + reseller.month }}
                  </div>
                  <div v-else-if="reseller.type == 'day'">
                    {{
                      reseller.year + "-" + reseller.month + "-" + reseller.day
                    }}
                  </div>
                  <div v-else>
                    {{
                      reseller.report_date_start +
                        " -> " +
                        reseller.report_date_end
                    }}
                  </div>
                </div>
              </div>
              <table class="table-auto w-full border-black">
                <thead class="border-black border-b-2">
                  <th>
                    <div v-if="reseller.type == 'year'">
                      {{ $t("reports.month") }}
                    </div>
                    <div v-else-if="reseller.type == 'month'">
                      {{ $t("reports.day") }}
                    </div>
                    <div v-else-if="reseller.type == 'day'">
                      {{ $t("reports.hour") }}
                    </div>
                    <div v-else>{{ $t("reports.day") }}</div>
                  </th>
                  <th>{{ $t("reports.bills_count") }}</th>
                  <th>{{ $t("reports.amount") }}</th>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in rese.bills"
                    :key="index"
                    class="text-center border-b"
                  >
                    <td>{{ item.time }}</td>
                    <td>{{ item.count }}</td>
                    <td>{{ item.price }}</td>
                  </tr>
                  <tr class="bg-black text-white text-center">
                    <td>{{ $t("reports.total") }}</td>
                    <td>{{ rese.bills.amount_count }}</td>
                    <td>{{ rese.bills.amount_price }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <button
            v-if="report.reseller"
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white py-1 px-12 font-bold"
            v-print="'reseller_report'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-12 h-12"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import { mapGetters } from "vuex";
export default {
  name: "Reports",
  components: { LoadingOverlay },
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      finance: {
        type: "year",
        year: "2023",
        month: "01",
        day: "01",
        report_date_start: "",
        report_date_end: "",
      },
      sells: {
        type: "year",
        year: "2023",
        month: "01",
        day: "01",
        report_date_start: "",
        report_date_end: "",
      },
      reseller: {
        type: "year",
        year: "2023",
        month: "01",
        day: "01",
        report_date_start: "",
        report_date_end: "",
        user_id: -1,
      },
      report: {
        finance: null,
        sells: null,
        reseller: null,
      },
      resellers: [],
      loading: false,
    };
  },
  async created() {
    await this.fetchResellers();
  },
  methods: {
    async fetchSells() {
      this.loading = true;
      const response = await axios.get(
        `reports/sells?type=${this.sells.type}&year=${this.sells.year}&month=${this.sells.month}&day=${this.sells.day}&report_date_start=${this.sells.report_date_start}&report_date_end=${this.sells.report_date_end}`
      );
      if (response.status == 200) {
        this.report.sells = response.data.data;
        this.report.sells.accounts.count = 0;
        this.report.sells.accounts.forEach((account) => {
          this.report.sells.accounts.count += +account.count;
        });
        this.report.sells.cards.count = 0;
        this.report.sells.cards.forEach((card) => {
          this.report.sells.cards.count += +card.count;
        });
      }
      this.loading = false;
    },
    async fetchFinance() {
      this.loading = true;
      const response = await axios.get(
        `reports/finance?type=${this.finance.type}&year=${this.finance.year}&month=${this.finance.month}&day=${this.finance.day}&report_date_start=${this.finance.report_date_start}&report_date_end=${this.finance.report_date_end}`
      );
      if (response.status == 200) {
        this.report.finance = response.data.data;
        this.report.finance.amount = 0;
        this.report.finance.forEach((fin) => {
          this.report.finance.amount += +fin.amount;
        });
      }
      this.loading = false;
    },
    async fetchReseller() {
      this.loading = true;
      const response = await axios.get(
        `reports/reseller?type=${this.reseller.type}&year=${this.reseller.year}&month=${this.reseller.month}&day=${this.reseller.day}&report_date_start=${this.reseller.report_date_start}&report_date_end=${this.reseller.report_date_end}&user_id=${this.reseller.user_id}`
      );
      if (response.status == 200) {
        this.report.reseller = response.data.data;
        this.report.reseller.forEach((reseller) => {
          reseller.bills.amount_price = 0;
          reseller.bills.amount_paid = 0;
          reseller.bills.amount_count = 0;
          reseller.transactions.amount = 0;

          reseller.bills.forEach((fin) => {
            reseller.bills.amount_price += +fin.price;
            reseller.bills.amount_count += +fin.count;
          });
          reseller.transactions.forEach((fin) => {
            reseller.transactions.amount += +fin.amount;
          });
        });
      }
      this.loading = false;
    },
    async fetchResellers() {
      const response = await axios.get(
        "admins?filter=1&type=resellers&rows=10000&itr=true"
      );
      if (response.status == 200) this.resellers = response.data.data;
    },
    range(start, end, step = 1) {
      const len = Math.floor((end - start) / step) + 1;
      return Array(len)
        .fill()
        .map((_, idx) => start + idx * step);
    },
  },
};
</script>
