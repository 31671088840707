<template>
  <div class="main-content flex-1 relative bg-transparent">
    <loading-overlay :visible="loading" />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-20">
        <div class="flex flex-col justify-center items-center">
          <div class="grid grid-cols-2 gap-6 mt-6 lg:w-1/2">
            <div class="col-span-2" v-if="!support_ticket.account">
              <label class="font-bold">{{ $t("accounts.username") }}</label>
              <MultiSelect
                class="p-1 h-9 rounded-full shadow-lg"
                v-model="this.support_ticket.account_id"
                :close-on-select="false"
                @click="validate()"
                :class="{ 'shadow-error': errors.account_id }"
                :searchable="true"
                :placeholder="'NONE'"
                :options="accounts"
                :classes="{
                  search:
                    'w-full absolute rounded rounded-full inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white pl-3.5',
                  container:
                    'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-black rounded-full text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center whitespace-nowrap',
                }"
              />
            </div>
            <div class="col-span-2" v-if="support_ticket.account">
              <label class="font-bold">{{ $t("accounts.username") }}</label>
              <input
                v-model="support_ticket.account.username"
                :disabled="support_ticket.account"
                :class="{ 'bg-opacity-50': support_ticket.account }"
                @input="validate"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{ $t("support_tickets.issue") }}</label>
              <textarea
                class="bg-white rounded-3xl w-full h-24 shadow-lg p-1 pl-3 focus:outline-none"
                @input="validate"
                :disabled="support_ticket.account"
                v-model="support_ticket.issue"
                cols="30"
                rows="10"
                :class="{
                  'shadow-error': errors.issue,
                  'bg-opacity-50': support_ticket.account,
                }"
              ></textarea>
            </div>
            <div>
              <label class="font-bold mt-6">{{
                $t("support_tickets.status")
              }}</label>
              <select
                v-model="support_ticket.status"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none cursor-pointer"
              >
                <option
                  v-for="status in [
                    { name: $t('support_tickets.new'), id: 'new' },
                    {
                      name: $t('support_tickets.in_progress'),
                      id: 'in_progress',
                    },
                    { name: $t('support_tickets.resolved'), id: 'resolved' },
                    { name: $t('support_tickets.failed'), id: 'failed' },
                  ]"
                  v-bind:key="status.id"
                  :value="status.id"
                  >{{ status.name }}</option
                >
              </select>
            </div>
            <div>
              <label class="font-bold mt-6">{{
                $t("support_tickets.priority.priority")
              }}</label>
              <select
                v-model="support_ticket.priority"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none cursor-pointer"
              >
                <option
                  v-for="priority in [
                    { name: $t('support_tickets.priority.low'), id: '0' },
                    { name: $t('support_tickets.priority.normal'), id: '1' },
                    { name: $t('support_tickets.priority.high'), id: '2' },
                  ]"
                  v-bind:key="priority.id"
                  :value="priority.id"
                  >{{ priority.name }}</option
                >
              </select>
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{ $t("support_tickets.phone") }}</label>
              <input
                v-model="support_ticket.phone"
                :disabled="support_ticket.account"
                :class="{ 'bg-opacity-50': support_ticket.account }"
                @input="validate"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{
                $t("support_tickets.address")
              }}</label>
              <input
                v-model="support_ticket.address"
                :disabled="support_ticket.account"
                :class="{ 'bg-opacity-50': support_ticket.account }"
                @input="validate"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{ $t("support_tickets.notes") }}</label>
              <textarea
                class="bg-white rounded-3xl w-full h-24 shadow-lg p-1 pl-3 focus:outline-none"
                :disabled="support_ticket.account"
                :class="{ 'bg-opacity-50': support_ticket.account }"
                @input="validate"
                v-model="support_ticket.notes"
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div class="col-span-2">
              <label class="font-bold">{{
                $t("support_tickets.ssr_notes")
              }}</label>
              <textarea
                class="bg-white rounded-3xl w-full h-24 shadow-lg p-1 pl-3 focus:outline-none"
                @input="validate"
                v-model="support_ticket.ssr_notes"
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div class="col-span-2">
              <div
                class="text-gray-300 mt-0.5"
                :class="{ 'text-red-600': errors.account_id }"
              >
                *{{ errors.account_id }}
              </div>
              <div
                class="text-gray-300 mt-0.5"
                :class="{ 'text-red-600': errors.issue }"
              >
                *{{ errors.issue }}
              </div>
            </div>
            <div class="col-span-2">
              <button
                @click.prevent="store"
                class="w-full bg-green-500 text-white rounded-full  py-1 px-3"
                :class="{
                  'hover:bg-white hover:text-green-500': validated,
                  'bg-opacity-50 cursor-not-allowed': !validated,
                }"
                :disabled="!validated"
              >
                {{
                  this.support_ticket.id
                    ? $t("profiles.save")
                    : $t("profiles.add")
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import MultiSelect from "@vueform/multiselect";
import axios from "axios";
import ExpiredWindow from "../components/ExpiredWindow.vue";
export default {
  name: "AddSupportTicket",
  components: { LoadingOverlay, MultiSelect, ExpiredWindow },
  data() {
    return {
      loading: false,
      support_ticket: {
        account_id: "",
        username: "",
        issue: "",
        phone: "",
        address: "",
        priority: "0",
        status: "new",
        notes: "",
        ssr_notes: "",
        created_at: "",
      },
      accounts: [],
      errors: {
        account_id: null,
        issue: null,
      },
      validated: true,
    };
  },
  async created() {
    this.loading = true;
    if (this.$route.params.id) {
      await this.fetchSupportTicket();
    } else {
      await this.fetchAccounts();
    }
    this.loading = false;
  },
  methods: {
    async store() {
      if (this.support_ticket.account) {
        const res = await axios.put(
          `support/${this.support_ticket.id}`,
          this.support_ticket
        );
        if (res.status == 200) {
          this.$store.commit("changeKey");
          this.$router.push({
            path: "/services/support",
            query: { success: "success" },
          });
        }
      } else {
        const res = await axios.post("support", this.support_ticket);
        if (res.status == 200) {
          this.$store.commit("changeKey");
          this.$router.push({
            path: "/services/support",
            query: { success: "success" },
          });
        }
      }
    },
    async fetchAccounts() {
      const res = await axios.get("accounts?rows=1000000");
      const accounts = res.data.data;
      accounts.forEach((element) => {
        this.accounts.push({ value: element.id, label: element.username });
      });
    },
    async fetchSupportTicket() {
      const res = await axios.get(`support/${this.$route.params.id}`);
      this.support_ticket = res.data.data;
      this.support_ticket.account_id = this.support_ticket.account.id;
    },
    validate() {
      this.errors.account_id = null;
      this.errors.issue = null;
      this.validated = true;
      if (this.support_ticket.account_id == "") {
        this.errors.account_id = this.$t("errors.support_tickets.account_id");
        this.validated = false;
      }
      if (this.support_ticket.issue == "") {
        this.errors.issue = this.$t("errors.support_tickets.issue");
        this.validated = false;
      }
    },
  },
};
</script>
